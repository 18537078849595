//import { rootUrl } from '@/utils/helpers/urlHelpers'
//import { useAuthStore } from '@/stores/auth.store'
//import { showSnackbar } from '@/utils/showSnackbar'

// Lazy-loaded components

// Subdomain components
const FreeAgents = () => import('@/pages/tenant/FreeAgentsPage.vue')
const Home = () => import('@/pages/tenant/HomePage.vue')
const Rules = () => import('@/pages/tenant/RulesPage.vue')
const Schedule = () => import('@/pages/tenant/SchedulePage.vue')
const TeamPage = () => import('@/pages/tenant/TeamPage.vue')

// Referee Report
const GameMode = () => import('@/pages/tenant/GameModePage.vue')
const RefereeReport = () => import('@/pages/tenant/RefereeReportPage.vue')

// Public components (not restricted to subdomains)
const NotFoundPage = () => import('@/pages/common/NotFoundPage.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      default: Home,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/rules',
    name: 'rules',
    components: {
      default: Rules,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/schedule',
    name: 'schedule',
    components: {
      default: Schedule,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/game-mode/:id',
    name: 'gameMode',
    components: {
      default: GameMode,
    },
    meta: {
      roles: ['referee', 'admin', 'owner', 'engineer'],
      //layout: 'LobbyLayout'
      layout: 'BasicLayout'
    },
  },
  {
    path: '/referee-report',
    name: 'refereeReport',
    components: {
      default: RefereeReport,
    },
    meta: {
      roles: ['referee', 'admin', 'owner', 'engineer'],
      //layout: 'LobbyLayout'
      layout: 'BasicLayout'
    },
  },
  {
    path: '/free-agents',
    name: 'freeAgents',
    components: {
      default: FreeAgents,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/:league_nickname/:team_nickname',
    name: 'teamPage',
    components: {
      default: TeamPage,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notFound',
    component: NotFoundPage,
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout'
    }
  },
]

export default routes
